<template>
  <div id="app">
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status: 0,
      notice: {},
    };
  },
  mounted() {
    // const timer = setInterval(() => {
    //   this.getNoticeList();
    // }, 1000 * 60 * 5);
    // // 在 beforeDestroy 钩子中清除定时器
    // this.$once('hook:beforeDestroy', () => {
    //   clearInterval(timer);
    // });
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' })
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    },
    getNoticeList() {
      this.$http({
        method: 'get',
        url: 'sys_get_notice_list'
      }).then(res => {
        this.notice = res.data;
        this.showAppNotice();
      })
    },
    httpString(value) {
      var reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      var result = value.match(reg);
      return result;
    },
    showAppNotice() {
      if (this.notice != null && this.notice != undefined && this.notice.length > 0) {
        var confirmButtonText = this.$t("setting.ok");
        var titleText = this.$t("setting.tips");
        var content = this.notice[this.notice.length - 1].text;
        var httpStr = this.httpString(content);
        content = content.replace(httpStr, '');
        this.$dialog.alert({
          title: titleText,
          message: content + '<a href="' + httpStr + '" target="_blank">' + httpStr + '</a>',
          allowHtml: true,
          confirmButtonText: confirmButtonText,
        }).then(() => {
        });
      }
    }
  },
  created() {
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 600px;
}

body .van-toast .van-toast__icon {
  font-size: 50px;
}

.van-dialog {
  width: 80% !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  font-size: 20px !important;
  overflow: visible !important;
}

.van-dialog__header {
  color: rgb(50, 50, 51) !important;
  display: block !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding-top: 30px !important;
  text-align: center !important;
}

.van-dialog__message {
  color: rgb(50, 50, 51) !important;
  display: block !important;
  flex-basis: 0% !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  text-align: center !important;
}

.van-dialog__confirm,
.van-dialog__cancel {
  font-size: 24px !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}
</style>
