<template>
  <div class="home-container">
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="transparent" color="#fff"
          :text="this.notice" />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game">
        <!-- <div class="hot-title-div">
          <div>
            <span>{{ $t('index.task') }}</span>
          </div>
          <div @click="gotoMenu('/Game')">
            <span>{{ $t('index.more') }}</span>
            <van-icon name="arrow" color="#979799" />
          </div>
        </div> -->
        <!-- <div class="hot-items-div">
          <van-grid :column-num="4" :icon-size="10" :gutter="10" :square="true">
            <van-grid-item @click="doPay()">
              <div class="grid">
                <van-image class="game_item_img" src="img/home/recharge.png"></van-image>
                <span>{{ $t('index.recharge') }}</span>
              </div>
            </van-grid-item>
            <van-grid-item @click="doWithdrawal()">
              <div class="grid">
                <van-image class="game_item_img" src="img/home/withdraw.png"></van-image>
                <span>{{ $t('index.withdraw') }}</span>
              </div>

            </van-grid-item>
            <van-grid-item>
              <div class="grid" @click="toService()">
                <van-image class="game_item_img" src="img/home/betting.png"></van-image>
                <span class="eli-1">{{ $t('index.betting') }}</span>
              </div>

            </van-grid-item>
            <van-grid-item @click="showSetting()">
              <div class="grid">
                <van-image class="game_item_img" src="img/home/setting.png"></van-image>
                <span>{{ $t('index.setting') }}</span>
              </div>

            </van-grid-item>
          </van-grid>
        </div> -->
        <!-- <div class="hot-title-div">
          <div>
            <span>{{ $t('index.task') }}</span>
          </div>
        </div> -->
        <div class="c-tab">
          <div class="c-tab-inner">
            <div class="c-tab-item"
              :style="{ background: current_tab == -1 ? 'linear-gradient(to top,rgb(135, 206, 235), rgb(135, 206, 250))' : '', color: current_tab == -1 ? '#fff' : 'rgb(43 43 43)', borderRadius: '10px' }"
              @click="tabsSelectHandle(-1)">
              <!-- <img src="../images/class/class1_0.png" alt="" class="nav-img"> -->
              {{ $t('index.all') }}
            </div>
            <div class="c-tab-item" v-for="(item, index) in lotteryitem" :key="index"
              :style="{ background: current_tab == index ? 'linear-gradient(to top,rgb(135, 206, 235), rgb(135, 206, 250))' : '', color: current_tab == index ? '#fff' : 'rgb(43 43 43)', borderRadius: '10px' }"
              @click="tabsSelectHandle(index)">
              <div v-if="index == 0">
                <img src="../images/class/class1_1.png" alt="" class="nav-img" v-if="current_tab == index">
                <img src="../images/class/class1_0.png" alt="" class="nav-img" v-else>
              </div>
              <div v-if="index == 1">
                <img src="../images/class/class2_1.png" alt="" class="nav-img" v-if="current_tab == index">
                <img src="../images/class/class2_0.png" alt="" class="nav-img" v-else>
              </div>
              <div v-if="index == 2">
                <img src="../images/class/class3_1.png" alt="" class="nav-img" v-if="current_tab == index">
                <img src="../images/class/class3_0.png" alt="" class="nav-img" v-else>
              </div>
              <div v-if="index == 3">
                <img src="../images/class/class4_1.png" alt="" class="nav-img" v-if="current_tab == index">
                <img src="../images/class/class4_0.png" alt="" class="nav-img" v-else>
              </div>


              {{ item.name }}
            </div>

          </div>
        </div>
        <div>
          <div @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key" class="lottery-item">
            <div class="lottery-item-inner">
              <img :src="v.ico" alt="" class="lottery-item-inner-img">
              <div class="lottery-item-title">
                {{ v.name }}
              </div>
              <div class="lottery-item-dec">
                {{ v.desc }}
              </div>
            </div>
          </div>
        </div>
        <!-- <van-grid :column-num="2" :border="false">
          <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
            <span>{{ v.name }}</span>
            <span>{{ v.desc }}</span>
          </van-grid-item>
        </van-grid> -->

      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <!-- <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span>{{$t('index.hot')}}</span></div>
            <div>
              <span @click="gotoMenu('/Video')">{{$t('index.more')}}</span>
              <van-icon name="arrow" color="#979799" />
            </div>
          </div>
          <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div">
            <div>
              <span>{{$t('index.more')}}</span></div>
            <div @click="gotoMenu('/Video')">
              <span>{{$t('index.recmonmand')}}</span>
              <van-icon name="arrow" size="25" color="#979799" />
            </div>
          </div>
          <div class="movie_list_1">
            <div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <van-image class="cover_img"  round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>{{$t('video.play')}}:{{v.count}}</span>
                </div>
              </div>
            </div>
            <div class="hot-recommend-more" @click="gotoMenu('/Video')">{{$t('index.more')}}</div>
          </div>
        </div>-->
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current_tab: -1,
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      lotteryitem: [{}, {}, {}, {}],
      userInfo: {},
      baseInfo: {}
      // gameitem: [{}, {}, {}, {}]
    };
  },
  methods: {
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t("setting.forbid"));
      }
    },
    tabsSelectHandle(index) {
      this.current_tab = index
      this.$http({
        method: 'get',
        data: { class: index + 1 },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
        console.log(this.gameitem);
      })
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail(this.$t("setting.set_bank"));
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          // this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
        console.log(this.gameitem);
      })
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        console.log('res.data');
        console.log(res.data);
        this.lotteryitem = res.data;
      })
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    // toLottery(key, id) {
    //   if (!localStorage.getItem('token')) {
    //     this.$router.push({ path: '/Login' })
    //   } else {
    //     this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
    //   }

    // },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/PlayVideo?id=' + id })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("刷新成功");
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' })
        }
        this.baseInfo = res.data
        console.log(res.data);
      })
    },
    getNotice(data) {
      this.notice = data.notice;
    },
    // getGameItem() {
    //   this.$http({
    //     method: 'get',
    //     url: 'lottery_hot'
    //   }).then(res => {
    //     this.gameitem = res.data
    //   })
    // },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      console.log('data.banners');
      console.log(data.banners);
      this.banners = data.banners;
    },
    doPay() {
      if (this.baseInfo.isopen == 0) {
        this.$router.push({
          name: 'RechargeInfo',
          params: {
            'balance': this.userInfo.money
          }
        })
      } else {
        this.$router.push("ServiceOnline")
      }
    }
  },
  mounted() {

  },
  created() {
    this.getBasicConfig();
    this.getLotteryItem()
    this.getUserInfo()
    this.getBaseInfo()
    // this.getGameItem();//获取首页游戏列表
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.nav-img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.linear-bg {
  height: 200px;
  background: linear-gradient(90deg,#00bfff,#87ceeb,#87cefa);
}

.eli-1 {
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  // width: 100%;
  text-align: center;
}

.c-tab-item {
  white-space: nowrap;
  font-size: 32px;
  width: 140px;
  height: 140px;
  background: #FBECED;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.game_item_img {
  width: 205px;
  height: 205px;
  border-radius: 10px;
  overflow: hidden;
}

.c-tab-inner {
  // display: flex;
  padding: 10px;
}

.c-tab {
  width: fit-content;
  // overflow-x: scroll;
  // padding: 5px;
  background: #fff;
  // border-radius: 100px;
  // box-shadow: 0px 0px 3px rgba(62, 122, 233, 0.2);
  // margin: 10px auto;
  // position: absolute;
  // left: 20px;
  // top: 50%;
  // transform: translateY(-50%);
}

/deep/ .van-tabs__nav--card .van-tab.van-tab--active {
  background: linear-gradient(90.00deg, rgba(10, 207, 254, 1.00), rgba(73, 90, 255, 1.00) 100%);
}

/deep/.van-tabs__nav--card {
  border: 1px solid #eee;
  border-radius: 100px;
  overflow: hidden;
}

/deep/.van-tabs__nav--card .van-tab {
  border: 0;
}

/deep/ .van-tab,
.van-tabs__nav--card,
.van-tabs--card>.van-tabs__wrap {
  height: 50px;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px !important;
  // padding: 10px;
  // box-shadow: 0px 0px 3px rgba(62, 122, 233, 0.2);
  // border-radius:10px;
}

.notice-bar {
  background: linear-gradient(90deg,#00bfff,#87ceeb,#87cefa);
  width: 90%;
  border-radius: 100px;
  overflow: hidden;
  margin: 20px auto;
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fafafa;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
  // padding-left: 170px;
  display: flex;
  // justify-content: space-between;
}

.lottery-item {
  height: 200px;
  width: 580px;
  background: linear-gradient(to right, rgb(135, 206, 235), rgb(135, 206, 250));
  margin-bottom: 20px;
  border-radius: 50px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.lottery-item-inner-img {
  position: absolute;
  width: 200px;
  height: 80%;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

// .lottery-item:nth-child(1) .lottery-item-inner {
//   background-image: url('../images/class/class1_1.png');
//   background-size: 200px 100%;
//   background-position-x: right;
//   background-position-y: center;
//   background-repeat: no-repeat;
// }

// .lottery-item:nth-child(2) .lottery-item-inner {
//   background-image: url('../images/class/class2_1.png');
//   background-size: 200px 100%;
//   background-position-x: right;
//   background-position-y: center;
//   background-repeat: no-repeat;
// }
// .lottery-item:nth-child(3) .lottery-item-inner {
//   background-image: url('../images/class/class3_1.png');
//   background-size: 200px 100%;
//   background-position-x: right;
//   background-position-y: center;
//   background-repeat: no-repeat;
// }
// .lottery-item:nth-child(4) .lottery-item-inner {
//   background-image: url('../images/class/class4_1.png');
//   background-size: 200px 100%;
//   background-position-x: right;
//   background-position-y: center;
//   background-repeat: no-repeat;
// }

.lottery-item-dec {
  font-size: 32px;
}

.lottery-item-inner {
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 50px;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

// .hot-title-div>div:first-child span:before {
//   content: "";
//   display: block;
//   width: 5px;
//   height: 30px;
//   background-color: #7e5678;
//   border-radius: 1px;
//   margin-right: 5px;
// }

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 20px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 290px;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  width: 70px;
  height: 70px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
