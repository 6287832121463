import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import RechargeRecords from '../pages/mine/RechargeRecords'/* 充值记录 */
import RechargeInfo from '../pages/mine/RechargeInfo'


Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: 'home' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: 'home' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: 'choose' } },
    { path: '/List', name: 'list', component: List, meta: { title: 'list' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: 'profile' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: 'mine' } },
    { path: '/Video', name: 'video', component: Video, meta: { title: 'video' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: 'game' } },
    { path: '/Login', name: 'login', component: Login, meta: { title: 'login' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: 'register' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: 'serviceOnline' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: 'servicePage' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: 'setting' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: 'infomation' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: 'setname' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: 'setsex' } },
    { path: '/Language', name: 'Language', component: Language, meta: { title: 'language' } },
    { path: '/Recharge', name: 'Recharge', component: Recharge, meta: { title: 'recharge' } },
    { path: '/RechargeInfo', name: 'RechargeInfo', component: RechargeInfo, meta: { title: 'rechargeInfo' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: 'setPayPassword' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: 'setLoginPassword' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: 'lottery' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: 'notice' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: 'playVideo' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: 'setbank' } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: 'bindCard' } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: 'withdraw' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: 'personalreport' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: 'withdrawRecord' } },
    { path: '/RechargeRecords', name: 'RechargeRecord', component: RechargeRecords, meta: { title: 'rechargeRecord' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: 'gameRecord' } },


];

//生成路由实例
const router = new VueRouter({
    routes
})
export default router