<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({ path: '/WithdrawRecord' })">{{ $t("withdraw.with_record") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t("withdraw.with_money") }} ({{ $t("reservation.unit") }})</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">{{ $t('money_symbol') }}</span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t("index.all") }}</span>
        </div>
        <div class="information">


          <div class="balance">
            <span>{{ $t("my.balance") }}：</span>
            <span class="number">{{ this.userInfo.money }}{{ $t("reservation.unit") }}</span>
          </div>
        </div>
        <div class="description">
          <van-popover v-model="showPopover" trigger="click" placement="bottom-start">
            <div class="popover-body" style="padding: 10px;">
              <p>
                1.{{ $t("withdraw.single_limit") }}{{ $t("withdraw.low") }}{{ this.withdrawRole.min }}{{
                  $t("withdraw.heigh") }}{{ this.withdrawRole.max }}{{ $t("reservation.unit") }}
              </p>
              <p>2.{{ $t("withdraw.with_num") }}{{ this.withdrawRole.num }}{{ $t("withdraw.number") }}</p>
              <p>3.{{ $t("withdraw.with_tip") }}</p>
            </div>
            <template #reference @click="withdrawInfo()">
              <div style="display: flex;align-items: flex-end;">
                <van-icon name="info-o" />

                <div style="margin-left: 5px;">
                  {{ $t("withdraw.limit_desc") }}
                </div>
              </div>
            </template>
          </van-popover>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">
        {{ $t("withdraw.immediately_withdraw") }}</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      userInfo: {},
      withdrawRole: {},
      notice: {},
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getNoticeList() {
      this.$http({
        method: 'get',
        url: 'sys_get_notice_list'
      }).then(res => {
        this.notice = res.data
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole() {
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    httpString(value) {
      var reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      var result = value.match(reg);
      return result;
    },    
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("setting.correct_money"));
        return false;
      } else {
        if (this.notice != null && this.notice != undefined && this.notice.length > 0) {
          var confirmButtonText = this.$t("setting.ok");
          var titleText = this.$t("setting.tips");
          var content = this.notice[this.notice.length-1].text;
          var httpStr = this.httpString(content);
          content = content.replace(httpStr, '');          
          this.$dialog.alert({
            title: titleText,
            message: content + '<a href="' + httpStr + '" target="_blank">' + httpStr + '</a>',
            allowHtml: true,
            confirmButtonText: confirmButtonText,
          }).then(() => {
            this.applyWithdraw();
          });
        } else {
          this.applyWithdraw();
        }
      }
    },
    applyWithdraw() {
      this.$http({
        method: 'post',
        data: { money: this.withdraw_money },
        url: 'user_set_withdraw'
      }).then(res => {
        if (res.code === 200) {
          location.reload()
          this.$toast(res.msg);
          this.getUserInfo();
          this.getUserWithdrawRole();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    withdrawInfo() {
      this.showPopover = true;
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      // this.getNoticeList();
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}

.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  padding: 20px 43px;
  white-space: nowrap;
  font-size: 32px;
  background-color: #fff;
  color: #f78ca0;

}

.container .main .withdrawMoney span {
  color: #000;
  padding: 10px 0;
}

.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}

.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
  color: rgb(122, 122, 122);
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}

.container .main .withdrawMoney .information {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: row-reverse;
}

.container .main .withdrawMoney .information .description {
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // padding: 10px 0;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
  color: #f78ca0;
}

.van-popover--light {
  left: 18px !important;
  width: 90%
}

.withdraw_btn {
  /* 矩形 31 */

  width: 350px;
  height: 90px;
  color: #fff;

  margin: 60px auto;
  font-size: 30px;

  background: #f78ca0;

  border-radius: 100px;

}
</style>
